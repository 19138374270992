import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import talentLinkGlobalStyles from '../styles';

const JobSearchContainer = styled.div`
  ${talentLinkGlobalStyles};

  #lumesseSearchCriteriaWidget {
    button {
      margin-top: ${spacing(4)};
    }
  }
`;

export default JobSearchContainer;
