import React from 'react';
import JobSearchContainer from './styles';

const JobSearchForm: React.FC = () => (
  <JobSearchContainer>
    <div id="jobs-list" className="PSOFOShop container">
      <div className="row">
        <div className="twelve columns">
          <div id="lumesseSearchCriteriaWidget" className="search-results" />
        </div>
      </div>
    </div>
  </JobSearchContainer>
);

export default React.memo(JobSearchForm);
